import React from "react";
import styled from "styled-components";
import GoogleMapReact from "google-map-react";


const AnyReactComponent = () => <div><img src="https://a.static.getstamper.com/files/stampr_sygnet_mapa.png"></img></div>;

type Props = {
  center?: {
    lat?: number;
    lng?: number;
  };
  zoom?: number;
  height?: number;
};

const Map: React.FC<Props> = ({ center, zoom, height }) => {
  return (
    <div style={{ width: "100%", height: `${height}px` }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAwuZPyvQM8bo6aufVXmrlw0-60Tip1lao" }}
        defaultCenter={center}
        defaultZoom={zoom}
      >
	 <AnyReactComponent lat={49.4830259} lng={20.0220087}/>
<AnyReactComponent lat={49.6774439} lng={20.0743143}/>
<AnyReactComponent lat={49.721128} lng={18.9146954}/>
<AnyReactComponent lat={49.8278318} lng={19.9438777}/>
<AnyReactComponent lat={49.836327} lng={19.947543}/>
<AnyReactComponent lat={49.8497127} lng={19.3467626}/>
<AnyReactComponent lat={49.8797814} lng={20.0904692}/>
<AnyReactComponent lat={49.967521} lng={19.8486111}/>
<AnyReactComponent lat={49.9728265} lng={19.9003026}/>
<AnyReactComponent lat={49.9758426} lng={19.8213279}/>
<AnyReactComponent lat={49.9791691} lng={19.8296343}/>
<AnyReactComponent lat={50.0021188} lng={19.9039654}/>
<AnyReactComponent lat={50.0079737} lng={19.9168732}/>
<AnyReactComponent lat={50.0151982} lng={19.884923}/>
<AnyReactComponent lat={50.0161559} lng={19.9028126}/>
<AnyReactComponent lat={50.0161792} lng={20.0056471}/>
<AnyReactComponent lat={50.018289} lng={19.896456}/>
<AnyReactComponent lat={50.0187043} lng={21.9716617}/>
<AnyReactComponent lat={50.0188663} lng={19.9065684}/>
<AnyReactComponent lat={50.0191867} lng={19.9068198}/>
<AnyReactComponent lat={50.0211373} lng={19.8990128}/>
<AnyReactComponent lat={50.0233476} lng={18.5843411}/>
<AnyReactComponent lat={50.0275899} lng={22.017627}/>
<AnyReactComponent lat={50.0281206} lng={19.9044032}/>
<AnyReactComponent lat={50.0313392} lng={22.0054533}/>
<AnyReactComponent lat={50.0349712} lng={19.9337279}/>
<AnyReactComponent lat={50.036865} lng={22.0010619}/>
<AnyReactComponent lat={50.037093} lng={22.0051609}/>
<AnyReactComponent lat={50.037341} lng={19.94054}/>
<AnyReactComponent lat={50.0374173} lng={22.006253}/>
<AnyReactComponent lat={50.0376845} lng={22.0060273}/>
<AnyReactComponent lat={50.0377509} lng={22.0054522}/>
<AnyReactComponent lat={50.037866} lng={22.004431}/>
<AnyReactComponent lat={50.0420435} lng={22.0086499}/>
<AnyReactComponent lat={50.0425993} lng={19.9934428}/>
<AnyReactComponent lat={50.0432613} lng={19.9481657}/>
<AnyReactComponent lat={50.0453034} lng={19.9523808}/>
<AnyReactComponent lat={50.0460912} lng={19.9665542}/>
<AnyReactComponent lat={50.0490749} lng={19.962142}/>
<AnyReactComponent lat={50.0491927} lng={21.976293}/>
<AnyReactComponent lat={50.0503102} lng={18.6025415}/>
<AnyReactComponent lat={50.050362} lng={18.605719}/>
<AnyReactComponent lat={50.0506273} lng={18.6057722}/>
<AnyReactComponent lat={50.050644} lng={19.799547}/>
<AnyReactComponent lat={50.0512529} lng={19.9464729}/>
<AnyReactComponent lat={50.051509} lng={19.944313}/>
<AnyReactComponent lat={50.051578} lng={21.9920069}/>
<AnyReactComponent lat={50.052133} lng={19.9461231}/>
<AnyReactComponent lat={50.0522795} lng={19.9283957}/>
<AnyReactComponent lat={50.0535784} lng={19.9480525}/>
<AnyReactComponent lat={50.0547875} lng={19.9551499}/>
<AnyReactComponent lat={50.055856} lng={19.9284954}/>
<AnyReactComponent lat={50.0565559} lng={19.9292549}/>
<AnyReactComponent lat={50.057998} lng={19.949287}/>
<AnyReactComponent lat={50.0580749} lng={19.9387799}/>
<AnyReactComponent lat={50.0581824} lng={19.9196761}/>
<AnyReactComponent lat={50.059938} lng={19.9390334}/>
<AnyReactComponent lat={50.0605873} lng={19.9350935}/>
<AnyReactComponent lat={50.061242} lng={19.941398}/>
<AnyReactComponent lat={50.0622989} lng={19.9316408}/>
<AnyReactComponent lat={50.0627595} lng={19.9406693}/>
<AnyReactComponent lat={50.0633849} lng={19.9300871}/>
<AnyReactComponent lat={50.0637522} lng={19.9371902}/>
<AnyReactComponent lat={50.063911} lng={19.999929}/>
<AnyReactComponent lat={50.064237} lng={19.940746}/>
<AnyReactComponent lat={50.0649973} lng={19.926346}/>
<AnyReactComponent lat={50.0658649} lng={19.9305}/>
<AnyReactComponent lat={50.0661686} lng={22.2272744}/>
<AnyReactComponent lat={50.066246} lng={19.930028}/>
<AnyReactComponent lat={50.0665972} lng={19.9426361}/>
<AnyReactComponent lat={50.0669444} lng={19.9286624}/>
<AnyReactComponent lat={50.067133} lng={19.9500491}/>
<AnyReactComponent lat={50.0673856} lng={19.914864}/>
<AnyReactComponent lat={50.067517} lng={19.9776465}/>
<AnyReactComponent lat={50.0683308} lng={19.9180813}/>
<AnyReactComponent lat={50.068515} lng={19.9112488}/>
<AnyReactComponent lat={50.0686698} lng={19.9267172}/>
<AnyReactComponent lat={50.0688851} lng={19.9055855}/>
<AnyReactComponent lat={50.0699292} lng={19.9369792}/>
<AnyReactComponent lat={50.0702168} lng={19.9434684}/>
<AnyReactComponent lat={50.0703109} lng={19.9096361}/>
<AnyReactComponent lat={50.0704099} lng={19.9193842}/>
<AnyReactComponent lat={50.0706838} lng={19.9240069}/>
<AnyReactComponent lat={50.0707223} lng={19.9190821}/>
<AnyReactComponent lat={50.0708268} lng={19.9169081}/>
<AnyReactComponent lat={50.0709374} lng={19.9442253}/>
<AnyReactComponent lat={50.0711508} lng={19.9344003}/>
<AnyReactComponent lat={50.0715049} lng={19.9199379}/>
<AnyReactComponent lat={50.0715843} lng={19.9367166}/>
<AnyReactComponent lat={50.0719194} lng={19.9199659}/>
<AnyReactComponent lat={50.0727003} lng={19.9013568}/>
<AnyReactComponent lat={50.0728325} lng={18.5185775}/>
<AnyReactComponent lat={50.073108} lng={19.974943}/>
<AnyReactComponent lat={50.0743553} lng={19.936346}/>
<AnyReactComponent lat={50.075599} lng={19.900925}/>
<AnyReactComponent lat={50.075786} lng={19.9305633}/>
<AnyReactComponent lat={50.0783004} lng={20.030394}/>
<AnyReactComponent lat={50.0785619} lng={20.0248574}/>
<AnyReactComponent lat={50.0790657} lng={19.8703194}/>
<AnyReactComponent lat={50.0796321} lng={19.8952484}/>
<AnyReactComponent lat={50.0810419} lng={19.957148}/>
<AnyReactComponent lat={50.0826144} lng={19.9194982}/>
<AnyReactComponent lat={50.0838139} lng={19.944929}/>
<AnyReactComponent lat={50.0840147} lng={19.9356727}/>
<AnyReactComponent lat={50.0863609} lng={20.00473}/>
<AnyReactComponent lat={50.0872309} lng={19.897416}/>
<AnyReactComponent lat={50.0885279} lng={19.9475921}/>
<AnyReactComponent lat={50.0891271} lng={19.947504}/>
<AnyReactComponent lat={50.089157} lng={19.95862}/>
<AnyReactComponent lat={50.0891623} lng={19.8945977}/>
<AnyReactComponent lat={50.0922961} lng={19.8979662}/>
<AnyReactComponent lat={50.0926005} lng={19.896874}/>
<AnyReactComponent lat={50.0929745} lng={19.957986}/>
<AnyReactComponent lat={50.0942182} lng={20.0055914}/>
<AnyReactComponent lat={50.0942626} lng={19.9370934}/>
<AnyReactComponent lat={50.094502} lng={18.5413062}/>
<AnyReactComponent lat={50.1019743} lng={19.9682235}/>
<AnyReactComponent lat={50.1038281} lng={19.9260097}/>
<AnyReactComponent lat={50.106464} lng={19.926702}/>
<AnyReactComponent lat={50.1077906} lng={19.845077}/>
<AnyReactComponent lat={50.1489305} lng={18.6774678}/>
<AnyReactComponent lat={50.1570873} lng={19.9445426}/>
<AnyReactComponent lat={50.223096} lng={18.970298}/>
<AnyReactComponent lat={50.2280343} lng={18.9695351}/>
<AnyReactComponent lat={50.2286146} lng={18.9580754}/>
<AnyReactComponent lat={50.2289893} lng={18.9570916}/>
<AnyReactComponent lat={50.2292554} lng={18.9493831}/>
<AnyReactComponent lat={50.232048} lng={18.9972499}/>
<AnyReactComponent lat={50.2355254} lng={18.9791322}/>
<AnyReactComponent lat={50.2471117} lng={19.0322338}/>
<AnyReactComponent lat={50.2560846} lng={19.0316463}/>
<AnyReactComponent lat={50.257117} lng={19.02863}/>
<AnyReactComponent lat={50.257529} lng={19.027051}/>
<AnyReactComponent lat={50.2600455} lng={18.8583948}/>
<AnyReactComponent lat={50.2723391} lng={18.867437}/>
<AnyReactComponent lat={50.272489} lng={18.99739}/>
<AnyReactComponent lat={50.2726804} lng={18.7959299}/>
<AnyReactComponent lat={50.2775406} lng={19.1824305}/>
<AnyReactComponent lat={50.282049} lng={18.960356}/>
<AnyReactComponent lat={50.2893837} lng={19.1313683}/>
<AnyReactComponent lat={50.2921022} lng={18.6572907}/>
<AnyReactComponent lat={50.2921755} lng={18.6660538}/>
<AnyReactComponent lat={50.2926436} lng={18.946908}/>
<AnyReactComponent lat={50.2941907} lng={18.6648238}/>
<AnyReactComponent lat={50.2955959} lng={18.956505}/>
<AnyReactComponent lat={50.295648} lng={18.949767}/>
<AnyReactComponent lat={50.2965744} lng={18.6711324}/>
<AnyReactComponent lat={50.2975329} lng={18.6726221}/>
<AnyReactComponent lat={50.2984753} lng={18.9378335}/>
<AnyReactComponent lat={50.299772} lng={18.8468213}/>
<AnyReactComponent lat={50.3025664} lng={19.0073448}/>
<AnyReactComponent lat={50.3039669} lng={19.028459}/>
<AnyReactComponent lat={50.3071386} lng={18.7831827}/>
<AnyReactComponent lat={50.3155321} lng={19.0018338}/>
<AnyReactComponent lat={50.3186304} lng={18.8494335}/>
<AnyReactComponent lat={50.3197121} lng={17.5760504}/>
<AnyReactComponent lat={50.3217064} lng={18.770827}/>
<AnyReactComponent lat={50.322392} lng={17.57459}/>
<AnyReactComponent lat={50.3277852} lng={18.9342426}/>
<AnyReactComponent lat={50.332014} lng={18.882984}/>
<AnyReactComponent lat={50.332524} lng={18.147132}/>
<AnyReactComponent lat={50.338932} lng={18.196553}/>
<AnyReactComponent lat={50.3437907} lng={18.9252109}/>
<AnyReactComponent lat={50.3449382} lng={18.9238381}/>
<AnyReactComponent lat={50.345602} lng={18.2078}/>
<AnyReactComponent lat={50.3461752} lng={18.9216983}/>
<AnyReactComponent lat={50.3462186} lng={18.9154847}/>
<AnyReactComponent lat={50.3466348} lng={18.9156189}/>
<AnyReactComponent lat={50.3469479} lng={18.9209573}/>
<AnyReactComponent lat={50.3471494} lng={18.9207698}/>
<AnyReactComponent lat={50.3475778} lng={18.9194843}/>
<AnyReactComponent lat={50.3479465} lng={18.9234651}/>
<AnyReactComponent lat={50.3483125} lng={18.9174447}/>
<AnyReactComponent lat={50.3490796} lng={18.9224447}/>
<AnyReactComponent lat={50.3493154} lng={18.9228235}/>
<AnyReactComponent lat={50.3496626} lng={18.9314158}/>
<AnyReactComponent lat={50.3496862} lng={18.9242768}/>
<AnyReactComponent lat={50.3499573} lng={18.9195912}/>
<AnyReactComponent lat={50.3504925} lng={18.9246127}/>
<AnyReactComponent lat={50.3520576} lng={18.9199012}/>
<AnyReactComponent lat={50.352294} lng={18.234266}/>
<AnyReactComponent lat={50.3645774} lng={18.8513511}/>
<AnyReactComponent lat={50.3649} lng={18.844017}/>
<AnyReactComponent lat={50.3650872} lng={18.8477316}/>
<AnyReactComponent lat={50.3652717} lng={18.8478587}/>
<AnyReactComponent lat={50.3652852} lng={18.8477421}/>
<AnyReactComponent lat={50.3654732} lng={18.847271}/>
<AnyReactComponent lat={50.3656872} lng={18.8477316}/>
<AnyReactComponent lat={50.3685809} lng={18.8470109}/>
<AnyReactComponent lat={50.3691405} lng={18.8505437}/>
<AnyReactComponent lat={50.3691406} lng={18.8501435}/>
<AnyReactComponent lat={50.3732397} lng={18.9480343}/>
<AnyReactComponent lat={50.3822901} lng={18.2388929}/>
<AnyReactComponent lat={50.3918978} lng={18.8793963}/>
<AnyReactComponent lat={50.4753165} lng={17.9665023}/>
<AnyReactComponent lat={50.492531} lng={18.013475}/>
<AnyReactComponent lat={50.498048} lng={18.031307}/>
<AnyReactComponent lat={50.50811} lng={18.3125}/>
<AnyReactComponent lat={50.5093462} lng={18.2896541}/>
<AnyReactComponent lat={50.5102299} lng={18.3042576}/>
<AnyReactComponent lat={50.5102449} lng={18.3020415}/>
<AnyReactComponent lat={50.5107971} lng={18.3037973}/>
<AnyReactComponent lat={50.5108002} lng={18.3036803}/>
<AnyReactComponent lat={50.5111187} lng={18.3024497}/>
<AnyReactComponent lat={50.5122829} lng={18.304434}/>
<AnyReactComponent lat={50.580162} lng={22.044132}/>
<AnyReactComponent lat={50.58255} lng={18.514164}/>
<AnyReactComponent lat={50.6453499} lng={17.9974617}/>
<AnyReactComponent lat={50.6459219} lng={17.9849143}/>
<AnyReactComponent lat={50.6477013} lng={17.9860925}/>
<AnyReactComponent lat={50.6540412} lng={17.8995313}/>
<AnyReactComponent lat={50.657571} lng={17.757848}/>
<AnyReactComponent lat={50.6612087} lng={17.9342122}/>
<AnyReactComponent lat={50.6633856} lng={17.9281763}/>
<AnyReactComponent lat={50.6637887} lng={17.9382889}/>
<AnyReactComponent lat={50.6640913} lng={17.9276456}/>
<AnyReactComponent lat={50.6641147} lng={17.9363918}/>
<AnyReactComponent lat={50.6642913} lng={17.9276456}/>
<AnyReactComponent lat={50.6645114} lng={17.9295845}/>
<AnyReactComponent lat={50.664549} lng={17.9217932}/>
<AnyReactComponent lat={50.664722} lng={17.9277816}/>
<AnyReactComponent lat={50.667649} lng={17.933418}/>
<AnyReactComponent lat={50.668566} lng={17.9269076}/>
<AnyReactComponent lat={50.6686799} lng={17.9235349}/>
<AnyReactComponent lat={50.668823} lng={17.9733038}/>
<AnyReactComponent lat={50.6688259} lng={17.922748}/>
<AnyReactComponent lat={50.6688799} lng={17.9235349}/>
<AnyReactComponent lat={50.6695335} lng={17.9127683}/>
<AnyReactComponent lat={50.6696515} lng={17.9283119}/>
<AnyReactComponent lat={50.6697147} lng={17.9094081}/>
<AnyReactComponent lat={50.670244} lng={17.9343028}/>
<AnyReactComponent lat={50.6706842} lng={17.9686849}/>
<AnyReactComponent lat={50.670737} lng={18.248803}/>
<AnyReactComponent lat={50.6713601} lng={17.9643259}/>
<AnyReactComponent lat={50.6722026} lng={17.9242527}/>
<AnyReactComponent lat={50.6723302} lng={17.970131}/>
<AnyReactComponent lat={50.6748354} lng={17.9167859}/>
<AnyReactComponent lat={50.6788846} lng={17.9000867}/>
<AnyReactComponent lat={50.6795502} lng={18.2179036}/>
<AnyReactComponent lat={50.679587} lng={18.2218321}/>
<AnyReactComponent lat={50.6803535} lng={17.9472794}/>
<AnyReactComponent lat={50.6822437} lng={17.8774525}/>
<AnyReactComponent lat={50.708568} lng={17.943293}/>
<AnyReactComponent lat={50.7226711} lng={17.9001044}/>
<AnyReactComponent lat={50.8604533} lng={17.4688124}/>
<AnyReactComponent lat={50.9733579} lng={18.212915}/>
<AnyReactComponent lat={50.9739469} lng={18.211029}/>
<AnyReactComponent lat={50.9801209} lng={18.211026}/>
<AnyReactComponent lat={50.9801209} lng={18.211826}/>
<AnyReactComponent lat={50.9802493} lng={18.2096171}/>
<AnyReactComponent lat={51.0488549} lng={16.9600009}/>
<AnyReactComponent lat={51.0531418} lng={18.4519746}/>
<AnyReactComponent lat={51.0547849} lng={16.1846101}/>
<AnyReactComponent lat={51.0601292} lng={17.022084}/>
<AnyReactComponent lat={51.07715} lng={17.718332}/>
<AnyReactComponent lat={51.097612} lng={17.013179}/>
<AnyReactComponent lat={51.1009242} lng={17.0258493}/>
<AnyReactComponent lat={51.1019705} lng={17.0399567}/>
<AnyReactComponent lat={51.1187754} lng={16.9886836}/>
<AnyReactComponent lat={51.185105} lng={19.49774}/>
<AnyReactComponent lat={51.1956577} lng={19.4935641}/>
<AnyReactComponent lat={51.202308} lng={16.153319}/>
<AnyReactComponent lat={51.2626059} lng={20.9078905}/>
<AnyReactComponent lat={51.650922} lng={16.0732342}/>
<AnyReactComponent lat={51.704026} lng={19.4165703}/>
<AnyReactComponent lat={51.7444759} lng={19.4083557}/>
<AnyReactComponent lat={51.757306} lng={19.537644}/>
<AnyReactComponent lat={51.7657195} lng={18.0877986}/>
<AnyReactComponent lat={51.768742} lng={19.4553763}/>
<AnyReactComponent lat={51.7704619} lng={19.429526}/>
<AnyReactComponent lat={51.7733981} lng={19.4534516}/>
<AnyReactComponent lat={51.773508} lng={19.4385843}/>
<AnyReactComponent lat={51.7803999} lng={19.485}/>
<AnyReactComponent lat={51.7807603} lng={19.4476209}/>
<AnyReactComponent lat={51.7819691} lng={19.3953028}/>
<AnyReactComponent lat={51.7999361} lng={19.4709422}/>
<AnyReactComponent lat={51.8010468} lng={19.4601624}/>
<AnyReactComponent lat={51.8145949} lng={19.6903964}/>
<AnyReactComponent lat={52.084587} lng={21.0222217}/>
<AnyReactComponent lat={52.18935} lng={21.0032419}/>
<AnyReactComponent lat={52.1921253} lng={21.0611515}/>
<AnyReactComponent lat={52.2067489} lng={20.9684696}/>
<AnyReactComponent lat={52.2091665} lng={21.0157229}/>
<AnyReactComponent lat={52.2184574} lng={21.0136845}/>
<AnyReactComponent lat={52.2189032} lng={21.0147882}/>
<AnyReactComponent lat={52.219454} lng={21.0172901}/>
<AnyReactComponent lat={52.2247013} lng={21.0078614}/>
<AnyReactComponent lat={52.2271023} lng={21.000323}/>
<AnyReactComponent lat={52.228795} lng={21.0736344}/>
<AnyReactComponent lat={52.2294361} lng={21.0001841}/>
<AnyReactComponent lat={52.2335258} lng={21.0191449}/>
<AnyReactComponent lat={52.2341723} lng={21.0209662}/>
<AnyReactComponent lat={52.234282} lng={20.989703}/>
<AnyReactComponent lat={52.2343639} lng={20.9690673}/>
<AnyReactComponent lat={52.239241} lng={20.9480349}/>
<AnyReactComponent lat={52.239613} lng={20.996677}/>
<AnyReactComponent lat={52.2497392} lng={20.9821297}/>
<AnyReactComponent lat={52.2543466} lng={21.0818631}/>
<AnyReactComponent lat={52.255839} lng={21.04491}/>
<AnyReactComponent lat={52.28489} lng={21.0731412}/>
<AnyReactComponent lat={52.2939795} lng={20.9288222}/>
<AnyReactComponent lat={52.294481} lng={21.0528489}/>
<AnyReactComponent lat={52.2961575} lng={21.0429061}/>
<AnyReactComponent lat={52.2961575} lng={21.0435061}/>
<AnyReactComponent lat={52.3365839} lng={20.8959379}/>
<AnyReactComponent lat={52.72586} lng={15.244269}/>
<AnyReactComponent lat={54.319923} lng={18.5868985}/>
<AnyReactComponent lat={54.3313321} lng={18.557175}/>
<AnyReactComponent lat={54.340691} lng={18.876613}/>
<AnyReactComponent lat={54.3412566} lng={18.3745703}/>
<AnyReactComponent lat={54.3465251} lng={18.67513}/>
<AnyReactComponent lat={54.3526903} lng={18.6542176}/>
<AnyReactComponent lat={54.3837802} lng={18.5915396}/>
<AnyReactComponent lat={54.4050868} lng={18.5961926}/>
<AnyReactComponent lat={54.4251254} lng={18.4781331}/>
<AnyReactComponent lat={54.4768291} lng={17.0015779}/>
<AnyReactComponent lat={54.5131289} lng={18.5387865}/>

		
	     </GoogleMapReact>
    </div>
  );
};

Map.defaultProps = {
  center: {
    lat: 50.049683,
    lng: 19.944544,
  },
  zoom: 11,
  height: 697,
};

export { Map };
